<template>
	<div></div>
</template>

<script>
export default {
	name: "sessionService",
	components: {},
	mixins: [],
	computed: {
		usuario: {
			cache: false,
			get() {
				return this.$store.state.usuario.logado;
			},
			set(value) {
				this.$store.state.usuario.logado = value;
			},
		},
		isSuperUser: {
			cache: false,
			get() {
				return (
					this.$store.state.usuario.logado &&
          [1, 11].indexOf(this.$store.state.usuario.logado.intId) !== -1
				);
			},
		},
		configuracao: {
			cache: false,
			get() {
				return this.$store.state.util.configuracao;
			},
			set(value) {
				this.$store.state.util.configuracao = value;
			},
		},
		core: {
			cache: false,
			get(){
				return this.$store.state.config.core;
			},
			set(value){
				this.$store.state.config.core = value;
			}
		},
		cliente: {
			cache: false,
			get() {
				return this.$store.state.cliente.logado;
			},
			set(value) {
				this.$store.state.cliente.logado = value;
			},
		},
		versao: {
			cache: false,
			get() {
				return this.$store.state.cliente.versao;
			},
			set(value) {
				this.$store.state.cliente.versao = value;
			},
		},
		notification: {
			cache: false,
			get() {
				return this.$store.state.usuario.notification;
			},
			set(value) {
				this.$store.dispatch("usuario/setUsuarioNotification", value);
			},
		},
		isMobile: {
			cache: false,
			get() {
				return this.boolMobile;
			},
		},
		isProduction: {
			cache: false,
			get() {
				return Number(process.env.VUE_APP_PRODUCTION);
			},
		},
	},
	watch: {
		isMobile() {
			this.inicialize();
		},
	},
	data() {
		return {
			boolMobile: false,
		};
	},

	mounted() {
		window.addEventListener("resize", this.inicialize);
	},

	created() {
		this.inicialize();
	},

	methods: {
		inicialize() {
			this.boolMobile = window.innerWidth < 769;
		},

		clearSession() {
			this.$cookie.delete("rid");
		},

		logout() {
			this.clearSession();
			this.$router.push({ name: "login" });
		},
	},
};
</script>
