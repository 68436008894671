import moment from "moment";
import "moment/locale/pt-br";
import "moment-precise-range-plugin";
import Base64 from "base-64";
import $ from "jquery";
import store from '@/store/index'

let VueUtilidade = {
  loading(show) {
    if (show) {
      $(".loader-container").show();
    } else {
      $(".loader-container").hide();
    }
  },

  splash(show, message = null) {
    if (message) {
      $(".splash-message").html(message);
    }

    if (show) {
      $(".splash").show();
    } else {
      $(".splash").hide();
    }
  },

  getLocation(type) {
    var hostname = window.location;

    type = type ? type : "hostname";

    return hostname[type];
  },

  download(blobData, filename, type = "data:text/plain;charset=utf-8") {
    var element = document.createElement("a");
    element.setAttribute("href", type + ", " + encodeURIComponent(blobData));
    element.setAttribute("download", filename);
    document.body.appendChild(element);
    element.click();
  },

  b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    var byteCharacters = atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, {
      type: contentType,
    });

    return blob;
  },

  parseBoolean(bool) {
    if (Number(bool)) {
      return "Sim";
    }

    return "N�o";
  },

  randomMumber(min, max) {
    min = !min ? 1 : min;
    max = !max ? 10 : max;
    return Math.random() * (max - min) + min;
  },

  randomString() {
    let math =
      Math.random().toString(36).substring(2, 15) +
      Math.random().toString(36).substring(2, 15);
    return this.toMd5(math + new Date().getTime());
  },

  onlyNumber(value) {
    if (!value) return "";

    value = value.toString();

    return value.replace(/[.,: -]+/g, "-").replace(/[^0-9.]+/g, "");
  },

  onlyText(value) {
    if (!value) return "";

    value = value.toString(); // Convertir el valor a cadena si no lo es
    return value.replace(/<[^>]*>/gi, ""); // Eliminar etiquetas HTML
  },

  checkPassword(value) {
    if (!value) return false;

    var regExpLetter = /[a-zA-Z]/g;
    var regExpNumber = /[0-9]/g;

    return (
      regExpLetter.test(value) &&
      regExpNumber.test(value) &&
      value.toString().length >= 6
    );
  },

  isEmail(value) {
    if (!value) return false;

    let arrayEmail = value.split(";");
    let arrayEmailInvalido = [];
    let boolRetorno = false;

    const pattern =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (arrayEmail.length > 1) {
      boolRetorno = true;

      arrayEmail.forEach((email) => {
        if (!pattern.test(email)) {
          arrayEmailInvalido.push(email);
          boolRetorno = false;
        }
      });
    } else {
      boolRetorno = pattern.test(value);
    }

    return boolRetorno;
  },

  isDate(value) {
    if (!value) return false;

    let arrayValue = value.toString().split("/");

    if (arrayValue.length != 3) return false;

    let dia = arrayValue[0];
    let mes = arrayValue[1];
    let ano = arrayValue[2];
    let data = ano + "-" + mes + "-" + dia;

    if (
      parseInt(mes) > 12 ||
      parseInt(mes) < 1 ||
      parseInt(dia) < 1 ||
      parseInt(dia) > 31 ||
      parseInt(ano) < 1900
    )
      return false;

    return moment(data).isValid();
  },

  isCpf(cpf) {
    cpf = cpf.toString().replace(/[^\d]+/g, "");
    if (cpf == "") return false;
    // Elimina CPFs invalidos conhecidos
    if (
      cpf.length != 11 ||
      cpf == "00000000000" ||
      cpf == "11111111111" ||
      cpf == "22222222222" ||
      cpf == "33333333333" ||
      cpf == "44444444444" ||
      cpf == "55555555555" ||
      cpf == "66666666666" ||
      cpf == "77777777777" ||
      cpf == "88888888888" ||
      cpf == "99999999999"
    )
      return false;
    // Valida 1o digito
    let add = 0;
    for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
    let rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpf.charAt(9))) return false;
    // Valida 2o digito
    add = 0;
    for (i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
    rev = 11 - (add % 11);
    if (rev == 10 || rev == 11) rev = 0;
    if (rev != parseInt(cpf.charAt(10))) return false;
    return true;
  },

  isPis(pis) {
    pis = pis.replace(/[^\d]+/g, "");

    var multiplicadorBase = "3298765432";
    var total = 0;
    var resto = 0;
    var multiplicando = 0;
    var multiplicador = 0;
    var digito = 99;

    // Retira a mascara
    var numeroPIS = pis;

    if (
      numeroPIS.length !== 11 ||
      numeroPIS === "00000000000" ||
      numeroPIS === "11111111111" ||
      numeroPIS === "22222222222" ||
      numeroPIS === "33333333333" ||
      numeroPIS === "44444444444" ||
      numeroPIS === "55555555555" ||
      numeroPIS === "66666666666" ||
      numeroPIS === "77777777777" ||
      numeroPIS === "88888888888" ||
      numeroPIS === "99999999999"
    ) {
      return false;
    } else {
      for (var i = 0; i < 10; i++) {
        multiplicando = parseInt(numeroPIS.substring(i, i + 1));
        multiplicador = parseInt(multiplicadorBase.substring(i, i + 1));
        total += multiplicando * multiplicador;
      }

      resto = 11 - (total % 11);
      resto = resto === 10 || resto === 11 ? 0 : resto;

      digito = parseInt("" + numeroPIS.charAt(10));
      return resto === digito;
    }
  },

  isCei(cei) {
    cei = cei.replace(/[^\d]+/g, "");

    if (cei == "") {
      return false;
    }

    if (cei.length != 12) {
      return false;
    }

    var peso = "74185216374";
    var soma = 0;

    //Faz um for para multiplicar os n�meros do CEI digitado pelos n�meros do peso.
    //E somar o total de cada n�mero multiplicado.
    for (var i = 1; i < 12; i++) {
      var fator = peso.substring(i - 1, i);
      var valor = cei.substring(i - 1, i);
      soma += fator * valor;
    }
    //Pega o length do resultado da soma e desconta 2 para pegar somente a dezena.
    var len = soma.toString().length - 2;

    //pega a dezena
    var dezena = soma.toString().substring(len);

    //pega o algarismo da dezena
    var algdezena = dezena.toString().substring(0, 1);

    //pega o algarismo da unidade
    var unidade = parseInt(soma) - parseInt(soma / 10) * 10;

    //soma o algarismo da dezena com o algarismo da unidade.
    soma = parseInt(algdezena) + unidade;

    //pega o d�gito (�ltimo n�mero) do cei digitado.
    var digitoCEI = cei.substring(11);
    var digitoEncontrado = 10 - soma;

    if (digitoCEI != digitoEncontrado) {
      return false;
    } else {
      return true;
    }
  },

  mascara(val, mask) {
    if (!val) return val;

    val = this.onlyNumber(val);

    let num = this.onlyNumber(val);

    if (this.isCnpj(num)) {
      mask = "cnpj";
    } else if (this.isCpf(num)) {
      mask = "cpf";
    } else if (num.toString().length == 10) {
      mask = "telefone";
    } else if (num.toString().length == 1) {
      mask = "celular";
    }

    switch (mask) {
      case "cnpj":
        mask = "##.###.###/####-##";
        break;

      case "cpf":
        mask = "###.###.###-##";
        break;

      case "telefone":
        mask = "(##) ####-####";
        break;

      case "celular":
        mask = "(##) #####-#####";
        break;

      default:
        mask = "";
        break;
    }

    let maskared = "";
    let k = 0;

    for (let i = 0; i <= mask.length - 1; i++) {
      if (mask[i] == "#") {
        if (val[k]) maskared += val[k++];
      } else {
        if (mask[i]) maskared += mask[i];
      }
    }

    return maskared;
  },

  isCnpj(value) {
    if (!value) return false;

    let cnpj = this.onlyNumber(value);

    // Valida a quantidade de caracteres
    if (cnpj.length !== 14) return false;

    // Elimina inv�lidos com todos os caracteres iguais
    if (/^(\d)\1+$/.test(cnpj)) return false;

    // C�culo de valida��o
    let t = cnpj.length - 2,
      d = cnpj.substring(t),
      d1 = parseInt(d.charAt(0)),
      d2 = parseInt(d.charAt(1)),
      calc = (x) => {
        let n = cnpj.substring(0, x),
          y = x - 7,
          s = 0,
          r = 0;

        for (let i = x; i >= 1; i--) {
          s += n.charAt(x - i) * y--;
          if (y < 2) y = 9;
        }

        r = 11 - (s % 11);
        return r > 9 ? 0 : r;
      };

    return calc(t) === d1 && calc(t + 1) === d2;
  },

  isNumeric(value) {
    const pattern = /[0-9]/;

    return value && pattern.test(value);
  },

  buildFormData(formData, data, parentKey) {
    if (
      data &&
      typeof data === "object" &&
      !(data instanceof Date) &&
      !(data instanceof File)
    ) {
      Object.keys(data).forEach((key) => {
        this.buildFormData(
          formData,
          data[key],
          parentKey ? `${parentKey}[${key}]` : key,
        );
      });
    } else {
      const value = data == null ? "" : data;

      formData.append(parentKey, value);
    }
  },

  toFormUpload(data) {
    const formData = new FormData();

    this.buildFormData(formData, data);

    return formData;
  },

  booleanToInteger(form) {
    for (var key in form) {
      if (form[key] === true) form[key] = 1;

      if (form[key] === false) form[key] = 0;
    }

    return form;
  },

  base64Decode(value) {
    try {
      return Base64.decode(value);
    } catch (err) {
      return err;
    }
  },

  base64Encode(value) {
    try {
      return Base64.encode(value);
    } catch (err) {
      return err;
    }
  },

  fileToBase64(file) {
    var reader = new FileReader();
    return new Promise((resolve, reject) => {
      reader.onerror = () => {
        reader.abort();
        reject(new Error("Error parsing file"));
      };
      reader.onload = function () {
        //This will result in an array that will be recognized by C#.NET WebApi as a byte[]
        let bytes = Array.from(new Uint8Array(this.result));

        //if you want the base64encoded file you would use the below line:
        let base64StringFile = btoa(
          bytes.map((item) => String.fromCharCode(item)).join(""),
        );

        //Resolve the promise with your custom file structure
        resolve({
          intTamanho: file.size,
          strConteudo: base64StringFile,
          strNome: file.name,
          strTipo: file.type,
        });
      };
      reader.readAsArrayBuffer(file);
    });
  },

  utf8Decode(t) {
    if (!t) return t;

    try {
      return decodeURIComponent(escape(t));
    } catch (err) {
      return t;
    }
  },

  diffDate(date1, date2, type = "minute") {
    var a = moment(date1);
    var b = moment(date2);

    switch (type) {
      case "minutes":
        return a.diff(b, "minutes");

      case "hours":
        return a.diff(b, "hours");

      case "days":
        return a.diff(b, "days");

      case "weeks":
        return a.diff(b, "weeks");

      default:
        return a.diff(b, "minutes");
    }
  },

  getInicioMes(format = "DD/MM/YYYY", data = null) {
    if (!data) {
      data = moment();
    }
    return moment(data).startOf("month").format(format, "pt_BR").toUpperCase();
  },

  getFimMes(format = "DD/MM/YYYY", data = null) {
    if (!data) {
      data = moment();
    }
    return moment(data).endOf("month").format(format, "pt_BR").toUpperCase();
  },
  date(value){   
    if (/^([0-9]{4})-([0-9]{2})/.test(value)) {
      return moment(value).format("DD-MM-YYYY");
  }

  return value;
  },
  toDate(date, disableHours, format) {
    if (!date) {
      date = new Date();
    }

    if (!format) {
      format = "DD/MM/YYYY HH:mm:ss";

      if (disableHours) {
        format = "DD/MM/YYYY";
      }
    }

    let objDate = moment(date);
    return objDate.format(format, "pt_BR").toUpperCase();
  },

  convertDate(date, format) {
    if (!date) {
      return new Date();
    }

    let arrayData1 = date.toString().split(" ");
    let arrayData2 = arrayData1[0].split("/");

    if (arrayData2.length == 3) {
      date = arrayData2[2] + "-" + arrayData2[1] + "-" + arrayData2[0];

      if (arrayData1.length == 2) {
        date += " " + arrayData1[0];
        date = date.trim();
      }
    }

    format = format ? format : "DD/MM/YYYY";

    return moment(date).format(format);
  },

  addDate(value, date, type = "D", format) {
    if (!date) {
      date = new Date();
    } else {
      date = new Date(date);
    }

    switch (type) {
      case "y":
        date = moment(date).add(value, "years").toDate();
        break;
      case "M":
        date = moment(date).add(value, "months").toDate();
        break;
      case "D":
        date = moment(date).add(value, "days").toDate();
        break;
      case "h":
        date = moment(date).add(value, "hours").toDate();
        break;
      case "m":
        date = moment(date).add(value, "minutes").toDate();
        break;
      case "s":
        date = moment(date).add(value, "secounds").toDate();
        break;
      default:
        date = moment(date).add(value, "days").toDate();
    }

    if (format) {
      return moment(date).format(format);
    }
    return date;
  },

  formataCpf(cpf) {
    //retira os caracteres indesejados...
    cpf = cpf.replace(/[^\d]/g, "");

    //realizar a formata��o...
    return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  },

  isTomorrow(strDate) {
    let arrayDate = strDate.split("/");

    if (arrayDate.length == 3) {
      strDate = arrayDate[2] + "-" + arrayDate[1] + "-" + arrayDate[0];
    }

    let strDataAtual = this.toDate(null, true, "YYYY-MM-DD");

    let intDifDay = this.diffDays(strDate, strDataAtual, "d");

    if (intDifDay > 0) return true;

    return false;
  },

  getAno() {
    let strDataAtual = new Date();

    return Number(strDataAtual.getFullYear());
  },

  getHora() {
    let strDataAtual = new Date();

    return Number(strDataAtual.getHours());
  },

  getSaudacao() {
    let intHora = this.getHora();

    if (intHora > 6 && intHora <= 12) {
      return "Bom dia";
    } else if (intHora > 12 && intHora <= 18) {
      return "Boa tarde";
    } else {
      return "Boa noite";
    }
  },

  calcularIdade(strDataNascimento) {
    if (
      !strDataNascimento ||
      this.onlyNumber(strDataNascimento).toString().length != 8
    )
      return null;

    let arrayDate = strDataNascimento.split("/");

    if (arrayDate.length == 3) {
      strDataNascimento =
        arrayDate[2] + "-" + arrayDate[1] + "-" + arrayDate[0];
    }

    let strDataAtual = this.toDate(null, true, "YYYY-MM-DD");

    var diff = moment.preciseDiff(strDataAtual, strDataNascimento, true);

    if (isNaN(diff.years)) return null;

    let retorno = diff.years + " anos";

    if (diff.months) {
      retorno +=
        " e " +
        diff.months.toFixed(0) +
        (diff.months.toFixed(0) == 1 ? " m�s" : " meses");
    }

    return retorno;
  },

  diffDays(date1, date2, type) {
    if (date1) {
      let arrayDate = date1.split("/");

      if (arrayDate.length == 3) {
        date1 = arrayDate[2] + "-" + arrayDate[1] + "-" + arrayDate[0];
      }
    }

    if (date2) {
      let arrayDate = date2.split("/");

      if (arrayDate.length == 3) {
        date2 = arrayDate[2] + "-" + arrayDate[1] + "-" + arrayDate[0];
      }
    }

    type = !type ? "d" : type;
    date1 = !date1 ? moment() : moment(date1);
    date2 = !date2 ? moment() : moment(date2);

    switch (type) {
      case "y":
        return date1.diff(date2, "years", true);
      case "d":
        return date1.diff(date2, "days", true);
      case "h":
        return date1.diff(date2, "hours", true);
      case "m":
        return date1.diff(date2, "minutes", true);
      case "s":
        return date1.diff(date2, "seconds", true);
      default:
        return date1.diff(date2, "days", true);
    }
  },

  money(value, casasDecimais = 2){
    if (value != null) {
      let formatter = new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
        minimumFractionDigits:casasDecimais
      });
  
      value = formatter
        .format(value)
        .trim();
    }
    return value;
  },

  moneyToFloat(value) {
    if (!value || value == "") {
      return 0;
    }

    if (value.toString().indexOf(",") == -1) {
      return parseFloat(value);
    }

    value = this.toString(value);

    return parseFloat(value.replace(".", "").replace(",", "."));
  },

  floatToMoney(value) {
    if (!value) {
      return "0,00";
    }

    if (value.toString().indexOf(",") != -1) {
      return value;
    }

    value = parseFloat(value);

    var formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });

    value = formatter.format(value).replace("R$", "").trim();

    if (isNaN(value)) {
      //return '0,00';
    }

    return value;
  },

  formataCampo(text, left, format) {
    format = !format ? "0" : format;
    left = !left ? 0 : left;

    if (!text) return text;

    return text.toString().padStart(left, format);
  },

  toMb: function (bytes) {
    if (!bytes) return "0 MB";

    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "n/a";
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
    if (i === 0) return `${bytes} ${sizes[i]}`;
    return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
  },

  toString(value) {
    if (value == null || value == undefined) return "";

    return value.toString();
  },

  validateEmail(email) {
    email = email ? email.trim() : null;

    if (/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      return true;
    }
    return false;
  },

  textLimit(value, limit) {
    limit = limit ? limit : 100;

    if (value && value.length > limit) {
      return value.substring(0, limit) + "...";
    }

    return value;
  },

  console(data, info = "") {
    if (process.env.VUE_APP_LOG == 1) {
      console.log("DEBUG :: " + data, info);
    }
  },

  removerAcentos(source = "") {
    if (!source) {
      return "";
    }

    var accent = [
        /[\300-\306]/g,
        /[\340-\346]/g, // A, a
        /[\310-\313]/g,
        /[\350-\353]/g, // E, e
        /[\314-\317]/g,
        /[\354-\357]/g, // I, i
        /[\322-\330]/g,
        /[\362-\370]/g, // O, o
        /[\331-\334]/g,
        /[\371-\374]/g, // U, u
        /[\321]/g,
        /[\361]/g, // N, n
        /[\307]/g,
        /[\347]/g, // C, c
      ],
      noaccent = [
        "A",
        "a",
        "E",
        "e",
        "I",
        "i",
        "O",
        "o",
        "U",
        "u",
        "N",
        "n",
        "C",
        "c",
      ];

    for (var i = 0; i < accent.length; i++) {
      source = source.replace(accent[i], noaccent[i]);
    }

    return source;
  },

  getEstado(search = null) {
    let arrayEstados = [
      {
        uf: "AC",
        estado: "Acre",
      },
      {
        uf: "AL",
        estado: "Alagoas",
      },
      {
        uf: "AP",
        estado: "Amap�",
      },
      {
        uf: "AM",
        estado: "Amazonas",
      },
      {
        uf: "BA",
        estado: "Bahia",
      },
      {
        uf: "CE",
        estado: "Cear�",
      },
      {
        uf: "DF",
        estado: "Distrito Federal",
      },
      {
        uf: "ES",
        estado: "Esp�rito Santo",
      },
      {
        uf: "GO",
        estado: "Goi�s",
      },
      {
        uf: "MA",
        estado: "Maranh�o",
      },
      {
        uf: "MT",
        estado: "Mato Grosso",
      },
      {
        uf: "MS",
        estado: "Mato Grosso do Sul",
      },
      {
        uf: "MG",
        estado: "Minas Gerais",
      },
      {
        uf: "PA",
        estado: "Par�",
      },
      {
        uf: "PB",
        estado: "Para�ba",
      },
      {
        uf: "PR",
        estado: "Paran�",
      },
      {
        uf: "PE",
        estado: "Pernambuco",
      },
      {
        uf: "PI",
        estado: "Piau�",
      },
      {
        uf: "RJ",
        estado: "Rio de Janeiro",
      },
      {
        uf: "RN",
        estado: "Rio Grande do Norte",
      },
      {
        uf: "RS",
        estado: "Rio Grande do Sul",
      },
      {
        uf: "RO",
        estado: "Rond�nia",
      },
      {
        uf: "RR",
        estado: "Roraima",
      },
      {
        uf: "SP",
        estado: "S�o Paulo",
      },
      {
        uf: "SC",
        estado: "Santa Catarina",
      },
      {
        uf: "SE",
        estado: "Sergipe",
      },
      {
        uf: "TO",
        estado: "Tocantins",
      },
    ];

    return arrayEstados.filter(function (objEstado) {
      objEstado.estado = objEstado.estado.toUpperCase();

      return !search || objEstado.uf.indexOf(search) !== -1;
    });
  },

  pluck(array, key) {
    return array.map((o) => o[key]);
  },

  removerAcentos(source){
    if (!source) { return }

    var accent = [
        /[\300-\306]/g, /[\340-\346]/g, // A, a
        /[\310-\313]/g, /[\350-\353]/g, // E, e
        /[\314-\317]/g, /[\354-\357]/g, // I, i
        /[\322-\330]/g, /[\362-\370]/g, // O, o
        /[\331-\334]/g, /[\371-\374]/g, // U, u
        /[\321]/g, /[\361]/g, // N, n
        /[\307]/g, /[\347]/g, // C, c
    ],
        noaccent = ['A', 'a', 'E', 'e', 'I', 'i', 'O', 'o', 'U', 'u', 'N', 'n', 'C', 'c']

    for (var i = 0; i < accent.length; i++) {
        source = source.replace(accent[i], noaccent[i])
    }

    return source
  },
  cloneDeep(obj) {
    if (obj === null || typeof obj !== 'object') {
      return obj;
    }

    if (Array.isArray(obj)) {
      return obj.map((item) => this.cloneDeep(item)); // Use arrow function para preservar o contexto
    }

    const newObj = {};
    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        newObj[key] = this.cloneDeep(obj[key]); // `this` est� garantido
      }
    }

    return newObj;
  },
  numberConfigurado(value, strSignal, intCasasDecimais) {
    const objCookieConfiguracao = store.state.util.configuracao.formatoMonetario;
    let arrayParts = []

    if(value == undefined){
      value = 0
    };
    
    if (intCasasDecimais && String(intCasasDecimais).match(/[a-zA-Z]/)) {
      intCasasDecimais = objCookieConfiguracao.casasDecimais;
    }
  
    if (!strSignal) {
      strSignal = ''
    }

    if (intCasasDecimais) {
      arrayParts = (+value).toFixed(intCasasDecimais).split(".")
    } else {
      arrayParts.push((+value).toString())
    }
  
    arrayParts[0] = arrayParts[0].replace(/\B(?=(\d{3})+(?!\d))/g, objCookieConfiguracao.milhar)
  
    if (intCasasDecimais) {
      return strSignal + arrayParts.join(objCookieConfiguracao.decimal)
    }
  
    return strSignal + arrayParts[0]
  },
  sanitizeObject(arrayItem, arrayAttributosRemover, arrayCamadas) {
    if (!arrayAttributosRemover)
      throw "Informe um array com o nome dos atributos que deseja remover"

    if (!arrayCamadas)
      arrayCamadas = []

    if (!Array.isArray(arrayItem)) {
      for (let attributeElement in arrayItem) {
        if (arrayAttributosRemover.indexOf(attributeElement) >= 0) {
          delete arrayItem[attributeElement]
        }

        if (arrayCamadas.indexOf(attributeElement) >= 0) {
          this.sanitizeObject(arrayItem[attributeElement], arrayAttributosRemover, arrayCamadas)
        }
      }
    } else {
      for (const value of arrayItem) {
        this.sanitizeObject(value, arrayAttributosRemover, arrayCamadas)
      }
    }
  }
};

export default VueUtilidade;
