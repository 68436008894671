import { createApp } from "vue";
import "./plugins";

import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import cookie from "./plugins/cookie";
import utilidade from "./plugins/utilidade";
import socket from "./plugins/socket";
import './assets/tailwind.css'
import PrimeVue from 'primevue/config'
import Aura from '@primevue/themes/aura'


require("@/assets/scss/app.scss");
require("@/assets/css/app.css");
require("@/assets/css/font-awesome.css");
require("@/assets/css/print.css");


//GLOBAL
const app = createApp(App);

app.config.globalProperties.$cookie = cookie;
app.config.globalProperties.$utilidade = utilidade;
app.config.globalProperties.$socket = socket;

app.use(router);
app.use(store);

app.directive('rstooltip',{
  mounted(element, binding){
    const modifiers = binding.modifiers;
    element.style.position = 'relative';
    element.style.display = 'block';

    const objDomTooltip = document.createElement('span');
    objDomTooltip.classList.add('rs-tooltip');

    if (modifiers.top) {
      objDomTooltip.classList.add('rs-tooltip-top');
    } else if (modifiers.left) {
      objDomTooltip.classList.add('rs-tooltip-left');
    } else if (modifiers.bottom) {
      objDomTooltip.classList.add('rs-tooltip-bottom');
    } else if (modifiers.right) {
      objDomTooltip.classList.add('rs-tooltip-right');
    }

    objDomTooltip.innerHTML = binding.value;
    element.insertBefore(objDomTooltip, element.firstChild);
  }
})

app.directive("clickedoutside", {
  beforeMount(el, binding) {
    el.clickOutsideEvent = (event) => {
      if (!(el === event.target || el.contains(event.target))) {
        binding.value(event);
      }
    };
    document.body.addEventListener("click", el.clickOutsideEvent);
  },
  unmounted(el) {
    document.body.removeEventListener("click", el.clickOutsideEvent);
  },
});

app.use(PrimeVue, {
  theme: {
    preset: Aura
  },
  locale: {
    dayNames: ['Domingo', 'Segunda-feira', 'Ter�a-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'S�bado'],
    dayNamesMin: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'S�b'],
    monthNames: ['Janeiro', 'Fevereiro', 'Mar�o', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
    monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
    today: 'Hoje',
    clear: 'Apagar'
  },
  semantic: {
    primary: {
      50: '{indigo.50}',
      100: '{indigo.100}',
      200: '{indigo.200}',
      300: '{indigo.300}',
      400: '{indigo.400}',
      500: '{indigo.500}',
      600: '{indigo.600}',
      700: '{indigo.700}',
      800: '{indigo.800}',
      900: '{indigo.900}',
      950: '{indigo.950}'
    }
  },
  colorScheme: {
    light: {
      formField: {
        hoverBorderColor: "{primary.color}"
      }
    }
  }
})



app.mount("#app");
